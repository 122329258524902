"use client";

import { usePathname, useRouter } from "next/navigation";

const Person = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                stroke="#0C0C0C"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M10 10.833A4.167 4.167 0 1010 2.5a4.167 4.167 0 000 8.333zM16.667 17.5a6.667 6.667 0 10-13.334 0"
            ></path>
        </svg>
    );
};

const AuthStatusButton = ({ storyBookProps = null, name }) => {
    const fullPath = usePathname();
    const router = storyBookProps
        ? {
              push: () => {
                  console.log("click");
              },
          }
        : useRouter();
    const lastSlash = fullPath?.lastIndexOf("/");
    const planId = fullPath?.substring(lastSlash + 1);
    return (
        <button
            className="flex flex-row items-center gap-2"
            onClick={() => {
                router.push("/auth/user", {});
            }}
        >
            <Person />
            <div>{name ? name : "Login"}</div>
        </button>
    );
};

export { AuthStatusButton };
