import { cn } from "@/lib/utils";

const HeaderBackground = ({ className }) => {
    return (
        <div
            className={cn(
                "flex flex-row bg-gatha-primary-purple h-[--header-open-height] w-full overflow-hidden",
                className
            )}
        >
            <div className="bg-gatha-primary-green w-full h-full"></div>
            <div className="flex justify-start items-center w-fit h-full">
                <svg
                    width="1900px"
                    height="100%"
                    viewBox="10 3 193 15"
                    version="1.1"
                    id="svg5649"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="var(--gatha-primary-green)"
                        d="m 191.70679,-1.957423 c 0.0286,0.053905 0.0735,0.079569 0.13411,0.076937 2.30413,-0.1038967 4.6082,-0.1209929 6.91231,-0.051296 3.52716,0.1084967 6.40332,2.04369786 7.41135,5.4801121 0.17622,0.603645 0.26963,1.4098129 0.28012,2.4185153 2.91206,3.4570442 3.87048,10.2709996 -0.3196,12.8500546 0,-0.06181 -0.0307,-0.09272 -0.0927,-0.09272 l -3.79149,0.01581 -10.33688,-20.3876863 c -0.0234,-0.048687 -0.0618,-0.072992 -0.11439,-0.072992 -8.84685,0.00373 -17.69235,0.00298 -26.53659,-0.00224 -0.56421,0 -0.95343,-0.2373781 -1.16781,-0.712142 -0.1578,-0.348507 -0.24002,-0.5299964 -0.24658,-0.5444607 -21.9793,-39.157855 -21.97696,-37.641282 -16.58634,1.2605469 h -23.81814 c -0.15192,6.7e-5 -0.27987,-0.1134925 -0.29788,-0.2643384 12.42281,-3.7242089 60.49954,-6.6357488 68.57053,0.025648 z"
                        id="path1621"
                    />
                    <path
                        fill="var(--gatha-primary-green)"
                        d="m 58.777453,-1.7759336 c -0.126254,-0.2663142 -0.433996,-0.033551 -0.682548,-0.2880108 -0.165704,-0.3116827 -0.347848,-0.6121893 -0.546438,-0.9015195 7.575659,-14.1022571 21.147872,-5.8379917 29.387062,1.011985 0.05813,0.094376 -0.0203,0.2138998 -0.130221,0.1972734 -0.295896,-0.049283 -0.595675,0.03549 -0.826488,0.03549 -9.017793,0.00298 -18.035618,0.00448 -27.053412,0.00597 -0.0566,0 -0.105876,-0.020354 -0.147955,-0.061137 z"
                        id="path1623"
                    />
                    <path
                        fill="var(--gatha-primary-green)"
                        d="M 126.48983,18.739979 H 99.562713 L 87.107183,-1.6122041 a 0.112443,0.11244299 0 0 1 0.09663,-0.1716178 l 35.741087,0.033551 q 0.23477,0 0.26629,0.2327705 1.36707,10.0212363 3.17212,20.0720594 c 0.0196,0.106527 0.0431,0.104552 0.10656,0.185434 z"
                        id="path1629"
                    />
                    <path
                        fill="var(--gatha-primary-green)"
                        d="m 175.37494,18.761674 -24.67043,-0.0217 c 0,-0.242641 0.0117,-0.4695 -0.0333,-0.708198 -1.26916,-6.561145 -2.40875,-13.144645 -3.41876,-19.7505054 5.70895,-0.2367221 11.50268,0.00597 17.22349,-0.00597 0.26241,0 0.26241,0.3333868 0.34527,0.4892281 3.60738,6.616383 7.12532,13.2820783 10.55385,19.9970923 z"
                        id="path1631"
                    />
                    <path
                        fill="var(--gatha-primary-green)"
                        d="m 58.481548,-1.7108369 c 0.04997,0.1841188 0.209105,0.4655559 0.477389,0.84431102 4.22352,5.94764388 7.632314,11.65658688 12.493012,19.60650488 -20.906502,0.01446 -41.81301,0.0296 -62.7195185,0.0454 -1.5255381,0.0015 -4.6667081,-0.02744 -9.42350753,-0.0868 -0.027633,-0.245928 -0.040142,-0.49251 -0.037505,-0.739751 0.044731,-3.538996 0.038145,-7.077992 -0.019757,-10.6169862 C -0.80157503,4.1421509 -0.28673496,1.636841 2.3665274,-0.38712891 4.6114435,-1.7975972 6.619634,-1.7502527 9.1939906,-1.7561728 25.622501,-1.79037 42.051669,-1.7752597 58.481493,-1.7108408 Z"
                        id="path1633"
                    />
                </svg>
            </div>

            <div className="bg-gatha-primary-green w-full h-full"></div>
        </div>
    );
};

export { HeaderBackground };
