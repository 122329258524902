"use client";

import { HorizontalLogo } from "./ui/brand/horizontal-logo";
import { useRouter } from "next/navigation";

const Logo = ({ className = "" }) => {
    const router = useRouter();

    return (
        <button
            className={`select-none text-2xl flex items-center h-10 font-bold text-center ${className}`}
            onClick={() => {
                router.push("/");
            }}
        >
            <HorizontalLogo />
        </button>
    );
};

export default Logo;
