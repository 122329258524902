const HorizontalLogo = () => {
    return (
        <div className="w-[100px] h-[41px] fill-gatha-secondary-yello">
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1838.71 749.96"
            >
                <g>
                    <g>
                        <circle cx="467.03" cy="176.55" r="32.39" />
                        <path d="m369.07,392.55c-51.66,0-93.69-42.03-93.69-93.69s42.03-93.69,93.69-93.69,93.68,42.03,93.68,93.69-42.02,93.69-93.68,93.69Zm0-150.57c-31.36,0-56.88,25.52-56.88,56.88s25.52,56.88,56.88,56.88,56.87-25.52,56.87-56.88-25.51-56.88-56.87-56.88Z" />
                        <path d="m489.82,452c-26.1-11.88-53.17-20.87-80.69-28.79-24.35-7.01-49-13.2-72.74-22.13-20.48-7.7-40.88-17.81-59.5-30.33-8.59-5.78-16.15-11.76-23.76-19.04-9.02-8.63-14.71-17.33-22.86-26.26-15.12-16.55-40.07,4.53-30.11,23.23,9.14,17.18,21.28,32.55,33.99,47.21,11.9,13.72,23.8,27.76,37.51,39.72,27.86,24.28,60.41,40.9,94.39,54.83,16.21,6.65,32.91,11.98,48.42,20.2,14.01,7.42,28.59,15.56,36.44,27.97,1.9,3,2.54,4.84,3.13,7.16.19.75-.28,3.73-.89,5.29-.38.97-2.15,3-4.47,4.97-1.64,1.39-5.9,3.92-9.17,5.36-15.33,6.77-32.8,6.97-49.21,5.68-20.7-1.62-41.95-5.75-61.61-12.43-16.77-5.7-33.6-15.22-43.92-30.03-5.91-8.49-16.79-12.4-26.23-6.88-8.4,4.91-12.83,17.69-6.88,26.23,26.32,37.77,70.14,51.61,113.71,58.39,22.31,3.47,44.39,5.13,66.75,1.02,20.11-3.7,41.89-13.45,53.1-31.43,26.37-42.3-21.87-79.5-55.05-96.27-18.16-9.18-37.59-15.29-56.31-23.16-17.62-7.4-34.85-15.98-50.99-26.23-18.12-11.51-33.12-25.57-47.42-41.51-15.62-17.42-31.13-34.64-42.2-55.43-10.04,7.74-20.08,15.49-30.11,23.23,7.56,8.27,14.42,18.17,22.86,26.26,9.11,8.72,19.16,16.57,29.55,23.7,19.88,13.64,41.67,24.09,64.03,32.98,49.28,19.59,102.44,27.56,150.88,49.61,9.42,4.29,20.63,2.69,26.23-6.88,4.82-8.23,2.59-21.92-6.88-26.23h0Z" />
                    </g>
                    <path d="m376.92,748.61C170.9,748.61,3.3,581,3.3,374.97S170.9,1.35,376.92,1.35s373.64,167.6,373.64,373.63-167.62,373.64-373.64,373.64Zm0-706.62c-183.61,0-332.98,149.37-332.98,332.98s149.37,333,332.98,333,333-149.37,333-333S560.55,41.99,376.92,41.99Z" />
                </g>
                <g>
                    <g>
                        <path d="m1503.95,183.06c0,1.36,0,2.45-.27,3.8-1.9,8.42-9.51,14.67-18.2,14.67-10.05,0-18.47-8.15-18.47-18.47s8.42-18.2,18.47-18.2c8.69,0,16.3,6.25,18.2,14.4.27,1.36.27,2.44.27,3.8Z" />
                        <g>
                            <path d="m1590.89,257.5c-10.6-6.25-22.55-9.51-35.59-9.51-10.32,0-19.83,2.17-28.8,6.52-8.97,4.08-16.57,10.05-22.82,17.39v-92.65c-1.9-8.15-9.51-14.4-18.2-14.4-10.05,0-18.47,8.15-18.47,18.2v228.22c0,10.05,8.42,18.48,18.47,18.48,8.69,0,16.3-6.52,18.2-14.67.27-1.36.27-2.45.27-3.8s0-2.44-.27-3.8v-79.6c0-7.88,2.17-14.94,5.98-21.46,3.8-6.52,8.97-11.68,15.49-15.49,6.52-3.8,13.58-5.7,21.46-5.7s15.21,1.9,21.46,5.7c6.52,3.8,11.68,8.97,15.48,15.49,3.8,6.52,5.71,13.58,5.71,21.46v83.41c0,10.05,8.15,18.48,18.2,18.48s18.48-8.42,18.48-18.48v-92.37c0-13.04-2.99-24.99-9.51-35.59-6.25-10.86-14.67-19.29-25.54-25.81Z" />
                            <path d="m1420.82,253.15c-1.36-.27-2.44-.27-3.8-.27s-2.45,0-3.8.27h-14.94v-37.22c0-10.05-8.15-18.47-18.48-18.47-9.78,0-17.93,7.88-18.47,17.39v2.44l.27,35.86h-5.7c-1.36-.27-2.45-.27-3.8-.27s-2.45,0-3.8.27c-8.42,1.9-14.67,9.5-14.67,18.2,0,10.32,8.15,18.47,18.47,18.47h9.51l-.27,120.9v1.09c.27,9.78,8.69,17.93,18.47,17.93,10.32,0,18.48-8.42,18.48-18.48v-121.45h18.75c10.32,0,18.47-8.15,18.47-18.47,0-8.7-6.25-16.3-14.67-18.2Z" />
                            <path d="m1817.75,253.7c-9.51,0-17.66,7.6-17.93,17.11v.54c0,1.36,0,2.45.27,3.53l.27,4.35c-.27-.27-.54-.82-.54-1.09s-.27-.27-.27-.27c-6.79-8.96-14.13-15.48-23.64-20.92-10.05-5.98-21.19-8.96-33.96-8.96s-24.45,2.44-35.59,7.34c-10.87,4.62-20.65,11.14-29.07,19.56-8.15,8.42-14.94,18.2-19.56,29.34-4.62,11.14-7.06,22.82-7.06,35.59s2.45,25.26,7.34,36.68c4.89,11.14,11.41,21.19,20.1,29.88,8.69,8.7,18.75,15.76,30.16,20.65,11.41,4.89,23.64,7.06,36.68,7.06,11.95,0,22.28-2.72,31.24-8.15,8.69-5.43,15.76-12.77,20.92-22l.27,6.52v.82c0,1.36.27,2.44.27,3.8,1.63,7.06,7.06,12.77,14.13,14.67,1.36.27,2.99.54,4.62.54s3.26-.27,4.62-.54c8.42-2.18,14.4-9.78,14.4-18.48v-139.92c0-9.78-7.88-17.66-17.66-17.66Zm-28.53,116.55c-4.35,8.69-10.32,15.76-18.2,20.92-7.61,4.89-16.84,7.6-27.17,7.6s-19.02-2.71-27.44-7.6c-8.42-5.16-15.21-12.23-20.38-20.65-5.16-8.69-7.61-18.48-7.61-29.07s2.44-20.38,7.33-29.07c4.89-8.7,11.68-15.76,20.11-20.92,8.42-5.16,17.93-7.61,27.98-7.61s19.56,2.45,27.17,7.61c7.88,5.16,13.86,11.95,18.2,20.65,4.35,8.7,6.52,18.47,6.52,29.34s-2.17,20.1-6.52,28.8Z" />
                            <path d="m1289.05,253.42c-9.78,0-17.66,7.88-17.93,17.66v.27c0,1.36,0,2.45.27,3.53l.27,4.35c-6.52-9.24-14.67-16.58-24.45-22.28-9.78-5.98-21.19-8.96-33.96-8.96s-24.45,2.44-35.32,7.34c-11.14,4.62-20.65,11.14-29.07,19.56-8.42,8.42-14.94,18.2-19.56,29.34-4.89,11.14-7.06,22.82-7.06,35.59s2.45,25.26,7.06,36.68c4.89,11.14,11.68,21.19,20.38,29.88,8.69,8.7,18.75,15.76,30.16,20.65,11.41,4.89,23.64,7.06,36.41,7.06,11.95,0,22.55-2.72,31.24-8.15,8.97-5.43,16.03-12.77,21.19-22l.27,6.52v.82c0,1.36.27,2.72.27,4.08,1.63,7.06,7.06,12.5,14.13,14.4,1.36.27,2.99.54,4.62.54s3.26-.27,4.62-.54c8.15-2.18,14.4-9.78,14.4-18.48v-139.92c0-9.78-8.15-17.93-17.93-17.93Zm-28.25,116.82c-4.35,8.69-10.32,15.76-18.2,20.92-7.88,4.89-16.84,7.6-27.17,7.6s-19.29-2.71-27.71-7.6c-8.42-5.16-15.21-12.23-20.38-20.65-4.89-8.69-7.61-18.48-7.61-29.07s2.45-20.38,7.34-29.07c5.16-8.7,11.68-15.76,20.38-20.92,8.42-5.16,17.66-7.61,27.98-7.61s19.29,2.45,27.17,7.61c7.61,5.16,13.58,11.95,17.93,20.65,4.35,8.7,6.52,18.47,6.52,29.34s-1.9,20.1-6.25,28.8Z" />
                        </g>
                    </g>
                    <path d="m1075.66,252.75h-.02c-1.35-.27-2.43-.27-3.78-.27s-2.43,0-3.78.27h-.02c-7.06,1.63-12.77,7.06-14.4,14.4-.27,1.36-.27,2.44-.27,3.8s0,2.45.27,3.8v3.26c-5.7-8.97-13.31-16.3-22.28-21.74-8.96-5.7-19.56-8.42-31.51-8.42s-24.45,2.44-35.32,7.34c-11.14,4.89-20.92,11.41-29.34,20.1-8.15,8.42-14.67,18.48-19.56,29.61-4.62,11.14-7.06,23.36-7.06,36.14s2.44,24.72,7.06,36.13c4.89,11.14,11.41,20.92,19.56,29.34,8.42,8.7,18.2,15.22,29.34,20.1,10.87,4.89,22.82,7.34,35.32,7.34s22.55-2.72,31.51-8.15c8.97-5.7,16.57-12.77,22.28-21.73v29.88c0,6.79-1.63,13.04-4.89,18.74-2.99,5.98-7.34,10.87-12.77,14.94-5.43,4.08-11.41,7.34-18.2,9.51-6.79,2.44-13.85,3.53-21.19,3.53s-14.4-1.63-21.46-4.89c-7.06-3.26-13.04-7.88-18.47-13.31-1.63-1.9-3.26-3.8-4.35-5.7-1.63-2.45-3.87-4.48-6.49-5.91-2.62-1.43-5.6-2.24-8.73-2.24-2.72,0-4.89.54-7.34,1.36-1.63.74-3.14,1.71-4.49,2.87-4.05,3.45-6.65,8.54-6.65,14.25h0c0,2.72.54,4.89,1.36,7.34.27,0,.27.27.27.54.81,1.63,1.9,2.99,3.26,4.35,0,.27.27.54.54.81,1.9,2.99,4.35,5.71,6.79,8.42,8.69,8.97,18.75,16.03,30.16,21.19,11.41,5.16,23.36,7.88,35.59,7.88s24.18-2.18,35.32-6.25c11.41-4.08,21.19-9.78,29.88-17.39,8.69-7.34,15.76-16.3,20.92-26.62,5.16-10.05,7.61-21.19,7.61-33.42v-163.01c0-8.96-6.25-16.3-14.67-18.2Zm-30.97,116.82c-4.08,8.69-10.05,15.76-17.66,20.92-7.88,5.44-16.57,7.88-26.9,7.88-9.78,0-18.75-2.44-26.9-7.6-8.15-5.16-14.67-11.96-19.56-20.65-4.89-8.96-7.34-18.48-7.34-29.07s2.45-20.38,7.06-29.07c4.89-8.7,11.41-15.49,19.56-20.65,8.15-5.16,17.12-7.88,27.17-7.88s19.02,2.72,26.62,7.88c7.61,5.16,13.58,12.22,17.93,20.92,4.35,8.7,6.52,18.2,6.52,28.8s-2.17,19.83-6.52,28.52Z" />
                </g>
            </svg>
        </div>
    );
};

export { HorizontalLogo };
