"use client";

import React, { useState } from "react";

import { AuthStatusButton } from "./auth-status-button";
import { useRouter } from "next/navigation";

const DownArrow = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="8"
            fill="none"
            viewBox="0 0 12 8"
        >
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M1 1.5l5 5 5-5"
            ></path>
        </svg>
    );
};

type NavigationButtonProps = {
    name: string;
    Icon: React.ElementType;
    route: string;
    storyBookProps?: any;
};

const NavigationButton = ({
    name,
    Icon,
    route,
    storyBookProps,
}: NavigationButtonProps) => {
    const router = storyBookProps
        ? { push: () => console.log(route) }
        : useRouter();

    return (
        <button
            className="flex flex-row items-center gap-3 p-1 align-middle justify-start"
            onClick={() => {
                if (route.startsWith("http")) {
                    window.open(route, "_blank");
                } else {
                    router.push(route);
                }
            }}
        >
            <Icon />
            <div>{name}</div>
        </button>
    );
};

const Seperator = () => {
    return <div className="h-1 border-t border-gray-300 m-2 ml-0 mr-0" />;
};

const DashboardIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                stroke="#9B5EFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M7.5 2.5H3.333a.833.833 0 00-.833.833v5.834c0 .46.373.833.833.833H7.5c.46 0 .833-.373.833-.833V3.333A.833.833 0 007.5 2.5zM16.666 2.5H12.5a.833.833 0 00-.834.833v2.5c0 .46.374.834.834.834h4.166c.46 0 .834-.373.834-.834v-2.5a.833.833 0 00-.834-.833zM16.666 10H12.5a.833.833 0 00-.834.833v5.834c0 .46.374.833.834.833h4.166c.46 0 .834-.373.834-.833v-5.834a.833.833 0 00-.834-.833zM7.5 13.334H3.333a.833.833 0 00-.833.833v2.5c0 .46.373.833.833.833H7.5c.46 0 .833-.373.833-.833v-2.5a.833.833 0 00-.833-.833z"
            ></path>
        </svg>
    );
};

const BlogIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                stroke="#9B5EFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6.667 17.5h10a1.666 1.666 0 001.666-1.667v-1.666h-10v1.666A1.666 1.666 0 016.666 17.5zm0 0A1.667 1.667 0 015 15.833V4.167a1.667 1.667 0 00-3.333 0v2.5H5"
            ></path>
            <path
                stroke="#9B5EFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M15.834 14.167v-10A1.667 1.667 0 0014.167 2.5H3.333"
            ></path>
        </svg>
    );
};

const InfoIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <g
                stroke="#9B5EFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                clipPath="url(#clip0_982_6114)"
            >
                <path d="M10 18.333a8.333 8.333 0 100-16.667 8.333 8.333 0 000 16.667zM10 13.333V10M10 6.667h.008"></path>
            </g>
            <defs>
                <clipPath id="clip0_982_6114">
                    <path fill="#fff" d="M0 0H20V20H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
};

const Menu = ({ storyBookProps, options }) => {
    return (
        <div className="absolute top-0 -right-3 h-fit w-52  p-3 pr-1 z-50 drop-shadow-glow-small">
            <div className="bg-white rounded-lg h-full w-full relative">
                <div className="bg-white rounded-sm h-4 w-4 rotate-45 absolute -top-2 right-4"></div>
                <div className="flex flex-col p-4">
                    {options.showDashboard ? (
                        <>
                            <NavigationButton
                                name="Dashboard"
                                storyBookProps={storyBookProps}
                                Icon={DashboardIcon}
                                route="/dashboard"
                            />
                            <Seperator />
                        </>
                    ) : null}
                    <NavigationButton
                        storyBookProps={storyBookProps}
                        name="Blog"
                        Icon={BlogIcon}
                        route="/blog"
                    />
                    {/* <Seperator /> */}
                    {/* <NavigationButton
                        storyBookProps={storyBookProps}
                        name="About Us"
                        Icon={InfoIcon}
                        route="https://www.google.com"
                    /> */}
                </div>
            </div>
        </div>
    );
};

type NavigationMenuProps = {
    storyBookProps?: any;
    authButton?: React.ReactElement;
    options?: {
        showDashboard: boolean;
    };
};

const NavigationMenu = ({
    storyBookProps,
    authButton = null,
    options = { showDashboard: true },
}: NavigationMenuProps) => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div className=" relative w-fit">
            <div className="flex flex-row items-center gap-4 w-40  justify-end p-2 relative z-50">
                {!storyBookProps ? (
                    authButton
                ) : (
                    <AuthStatusButton
                        storyBookProps={storyBookProps}
                        name="Jan"
                    />
                )}
                <button
                    onClick={() => {
                        setMenuOpen(!menuOpen);
                    }}
                >
                    <DownArrow />
                </button>
            </div>
            <div className="relative">
                {menuOpen ? (
                    <Menu storyBookProps={storyBookProps} options={options} />
                ) : null}
            </div>
        </div>
    );
};

export default NavigationMenu;
